import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

function LegalnoticePage() {

    return (
        <Layout>
            <SEO title="Legal Notice" />
            <div className="mgt-medium column page-content about-page">
                This is the legal notice
            </div>
        </Layout>
    )
}

export default LegalnoticePage
